<template>
  <dashboard-layout v-slot="userAccess">
    <loader-animation v-if="isLoading" />
    <div class="w-full p-5" v-if="!isLoading">
      <div
        v-if="userAccess.userAccess.access.isRevoke"
        class="text-red-400 font-bold w-full text-center mb-5"
      >
        <p>
          Seu acesso foi expirado em
          {{ userAccess.userAccess.access.revoke_access_at }}.
        </p>
        <p>
          Entre em contato com o seu sindicato para liberação do seu acesso
          definitivo.
        </p>
        <p>
          Sindicato: {{ userAccess.userAccess.informations.syndicate.name }}.
          Telefone:
          {{
            userAccess.userAccess.informations.syndicate.telephone
              | VMask('(##) ####-####')
          }}
        </p>
      </div>

      <modal-layout>
        <template v-slot:header><h1>Atenção</h1></template>
        <template v-slot:body
          ><p>
            Seu acesso foi expirado! Por favor, entre em contato com seu
            sindicato para solicitar renovação do seu acesso!
            <br /><br />
            Sindicato: {{ userAccess.userAccess.informations.syndicate.name }}.
            <br />
            Telefone:
            {{
              userAccess.userAccess.informations.syndicate.telephone
                | VMask('(##) ####-####')
            }}
          </p></template
        >
      </modal-layout>

      <div class="p-4">
        <div class="flex mb-4 justify-between">
          <h1 class="text-3xl">
            Oportunidades
          </h1>
          <button
            class="bg-green-1000 text-white px-6 py-3 rounded rounded-md focus:outline-none"
            @click="plantOpportunity(userAccess.userAccess.access.isRevoke)"
          >
            Plantar Oportunidade
          </button>
        </div>

        <v-client-table
          ref="table"
          :data="vacancies"
          :columns="columns"
          :options="options"
        >
          <div
            class="flex justify-end"
            slot="actions"
            slot-scope="{ row, index }"
            v-if="row.status != 'Cancelado' && row.status != 'Finalizado'"
          >
            <router-link
              v-if="row.status == 'Agendar e entrevistar'"
              class="flex p-1 border-2 border-green-1002 w-max rounded-lg mr-4"
              :to="{ name: 'schedule-interview', params: { id: row.id } }"
              v-tooltip="{
                content: 'Agendar e entrevistar',
                placement: 'left'
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M2 22a8 8 0 1 1 16 0H2zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm7.363 2.233A7.505 7.505 0 0 1 22.983 22H20c0-2.61-1-4.986-2.637-6.767zm-2.023-2.276A7.98 7.98 0 0 0 18 7a7.964 7.964 0 0 0-1.015-3.903A5 5 0 0 1 21 8a4.999 4.999 0 0 1-5.66 4.957z"
                  fill="rgba(0,122,111,1)"
                />
              </svg>
            </router-link>
            <router-link
              v-else
              class="flex p-1 border-2 border-green-1002 w-max rounded-lg mr-4"
              :to="{ name: 'subscrible-participants', params: { id: row.id } }"
              v-tooltip="{ content: 'Processo Seletivo', placement: 'left' }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M2 22a8 8 0 1 1 16 0H2zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm7.363 2.233A7.505 7.505 0 0 1 22.983 22H20c0-2.61-1-4.986-2.637-6.767zm-2.023-2.276A7.98 7.98 0 0 0 18 7a7.964 7.964 0 0 0-1.015-3.903A5 5 0 0 1 21 8a4.999 4.999 0 0 1-5.66 4.957z"
                  fill="rgba(0,122,111,1)"
                />
              </svg>
            </router-link>
            <router-link
              class="flex p-1 border-2 border-green-1002 w-max rounded-lg mr-4"
              :to="{ name: 'edit-vacancy', params: { id: row.id } }"
              v-tooltip="{ content: 'Editar', placement: 'left' }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M12.9 6.858l4.242 4.243L7.242 21H3v-4.243l9.9-9.9zm1.414-1.414l2.121-2.122a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414l-2.122 2.121-4.242-4.242z"
                  fill="rgba(0,122,111,1)"
                />
              </svg>
            </router-link>
            <button
              class="flex p-1 border-2 border-green-1002 w-max rounded-lg mr-4"
              @click="cancelVacancy(row, index)"
              v-tooltip="{
                content: 'Cancelar Oportunidade',
                placement: 'left'
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                  fill="rgba(0,122,111,1)"
                />
              </svg>
            </button>
          </div>
          <div
            class="flex justify-end"
            slot="actions"
            slot-scope="{ row }"
            v-else-if="row.status == 'Finalizado'"
          >
            <router-link
              class="flex p-1 border-2 border-green-1002 w-max rounded-lg mr-4"
              :to="{ name: 'view-vacancy', params: { id: row.id } }"
              v-tooltip="{ content: 'Ver Oportunidade', placement: 'left' }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M1.181 12C2.121 6.88 6.608 3 12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9zM12 17a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm0-2a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
                  fill="rgba(0,122,111,1)"
                />
              </svg>
            </router-link>
            <router-link
              class="flex p-1 border-2 border-green-1002 w-max rounded-lg mr-4"
              :to="{
                name: 'list-participants-approved',
                params: { id: row.id }
              }"
              v-tooltip="{ content: 'Fim do Processo', placement: 'left' }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M2 22a8 8 0 1 1 16 0H2zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm7.363 2.233A7.505 7.505 0 0 1 22.983 22H20c0-2.61-1-4.986-2.637-6.767zm-2.023-2.276A7.98 7.98 0 0 0 18 7a7.964 7.964 0 0 0-1.015-3.903A5 5 0 0 1 21 8a4.999 4.999 0 0 1-5.66 4.957z"
                  fill="rgba(0,122,111,1)"
                />
              </svg>
            </router-link>
          </div>
        </v-client-table>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../layouts/DashboardLayout';
import { mapGetters } from 'vuex';
import axios from '@/utils/axios';
import LoaderAnimation from '../../../components/LoaderAnimation.vue';
import ModalLayout from '../../../components/Modal';

export default {
  name: 'Index',

  title() {
    return `${process.env.VUE_APP_NAME} | Oportunidades`;
  },

  components: {
    DashboardLayout,
    LoaderAnimation,
    ModalLayout
  },

  data() {
    return {
      isLoading: true,
      vacancies: [],
      columns: [
        'id',
        'office',
        'validity_from',
        'validity_to',
        'vacancies_available',
        'salary',
        'city',
        'status',
        'actions'
      ],
      options: {
        sortable: true,
        perPage: 10,
        perPageValues: [10, 15, 20, 25, 50],
        headings: {
          office: 'Oportunidade',
          validity_from: 'inc. das incricões',
          validity_to: 'fim das incrições',
          vacancies_available: 'Oport. disponiveis',
          salary: 'Salário',
          actions: 'Ações',
          city: 'município'
        },
        hiddenColumns: ['id'],
        sortIcon: {
          is: 'glyphicon-sort',
          base: 'glyphicon',
          up: 'glyphicon-chevron-up',
          down: 'glyphicon-chevron-down'
        },
        texts: {
          first: 'Primeiro',
          last: 'Último',
          filter: 'Filtrar:',
          filterBy: 'Filtrar por {column}',
          filterPlaceholder: 'Pesquise aqui...',
          count:
            '{from} - {to} de {count} oportunidades|{count} oportunidades|Uma vaga',
          limit: 'Registros por página:',
          page: 'Página:',
          noResults: 'Nenhuma vaga encontrada.',
          columns: 'Colunas'
        },
        pagination: {
          chunk: 5
        }
      }
    };
  },

  computed: {
    ...mapGetters('auth', {
      user: 'user',
      authSyndicate: 'syndicate',
      authAssociate: 'associate',
      isAvailable: 'isAvailable'
    })
  },

  props: {
    participant: {
      required: true,
      type: Number
    }
  },

  methods: {
    fetchVacancies() {
      const company = this.authAssociate.id ?? {
        id: this.authSyndicate.id,
        name: this.authSyndicate.name
      };
      const user = {
        user_id: this.user.id,
        role: this.user.role.name,
        company: company
      };
      axios.post('/api/vagas/index', user).then(({ data }) => {
        data.data.forEach(vacancy => {
          this.vacancies.push(vacancy);
        });
        this.isLoading = false;
      });

      // Guarda em local para poder abrir o curriculum a qualquer momento
      localStorage.setItem(
        'searchParams',
        JSON.stringify({
          'Palavra Buscada': '',
          Curso: null,
          Escolaridade: null,
          Sexo: null,
          'Idade Mínima': 18,
          'Idade Máxima': 90,
          'Cidades Selecionadas': null,
          'Nome do Talento': '',
          'Ano de Fim': null,
          'Curriculo Complementar': ''
        })
      );

      localStorage.setItem(
        'selectedParticipants',
        JSON.stringify({
          gold: [],
          silver: [],
          bronze: []
        })
      );
    },
    plantOpportunity(isRevoke) {
      if (isRevoke) {
        this.$modal.show('modal');
      } else {
        this.$router.push({ name: 'register-vacancy' });
      }
    },
    cancelVacancy(row, index) {
      this.$confirm({
        message: 'Tem certeza que deseja cancelar essa vaga?',
        button: {
          no: 'Não',
          yes: 'Sim'
        },
        callback: confirm => {
          if (confirm) {
            axios
              .put(`/api/vagas/status/${row.id}`, {
                status: 5
              })
              .then(() => {
                this.$toast.success('Vaga cancelada com Sucesso!');
                this.vacancies.splice(--index, 1);
                this.$router.go(0);
              });
          }
        }
      });
    }
  },
  beforeMount: function() {
    this.fetchVacancies();
  }
};
</script>

<style scoped></style>
